.like-modal {
    width: 100%;
    max-width: 700px;
    background: #fff;
    border-radius: 5px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    padding: 20px;
    margin: 15px auto;

    overflow-y: auto;
    max-height: 800px;
}

.like-modal__close {
    position: absolute;
    display: block;
    top: 25px;
    right: 125px;
    opacity: .5;
    transition: all .2s linear;
    stroke: #fff;
    z-index: 5;
    cursor: pointer;
    &:hover {
        opacity: 1;
    }
}

.like-modal__title {
    font-size: 19px;
    line-height: 28px;
    font-weight: 600;
    color: #272727;
    text-align: center;
    margin: 5px 0 20px;
}

.like-modal__users {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-flow: row dense;
    margin: 15px 0 0;
    max-width: 100%;
}

.like-modal__user {
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all .2s linear;

    .avatar_small {
        width: 90px;
        height: 90px;
    }
    
    .user__name {
        text-align: center;
        margin-bottom: 14px;
    }

    &:hover {
        color: #f77f75;
    }
}

.like-modal__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}