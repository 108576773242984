$search-bg: #ffffff;
$nav-border: #e4e4e4;
$upper-header-bg: #f1f1f1;
$nav-text: #272727;
$main-bg: #f3f4f4;
$white-bg: #ffffff;
$red-bg: #d9291a;
$grey-bg: #f1f1f1;
$red-text: #d9291a;
$dark-text: #272727;
$light-text: #beb8b8;
$grey-text: #999999;
$red-hover: #f77f75;
$grey-hover: #999999;

@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

@font-face {
	font-family: PlayfairDisplay;
	src: url('./fonts/PlayfairDisplay/PlayfairDisplay.eot?#iefix') format('embedded-opentype'),
		url('./fonts/PlayfairDisplay/PlayfairDisplay.woff') format('woff'),
		url('./fonts/PlayfairDisplay/PlayfairDisplay.ttf') format('truetype'),
		url('./fonts/PlayfairDisplay/PlayfairDisplay.svg#PlayfairDisplay') format('svg');
	font-weight: 700;
}

@font-face {
	font-family: Golos;
	src: url('./fonts/Golos-Regular/Golos-Regular.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Golos-Regular/Golos-Regular.woff') format('woff'),
		url('./fonts/Golos-Regular/Golos-Regular.ttf') format('truetype');
	font-weight: 400;
}

@font-face {
	font-family: Golos;
	src: url('./fonts/Golos-Medium/Golos-Medium.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Golos-Medium/Golos-Medium.woff') format('woff'),
		url('./fonts/Golos-Medium/Golos-Medium.ttf') format('truetype');
	font-weight: 500;
}

@font-face {
	font-family: Golos;
	src: url('./fonts/Golos-Semibold/Golos-Semibold.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Golos-Semibold/Golos-Semibold.woff') format('woff'),
		url('./fonts/Golos-Semibold/Golos-Semibold.ttf') format('truetype');
	font-weight: 600;
}

@font-face {
	font-family: Golos;
	src: url('./fonts/Golos-Bold/Golos-Bold.eot?#iefix') format('embedded-opentype'),
		url('./fonts/Golos-Bold/Golos-Bold.woff') format('woff'),
		url('./fonts/Golos-Bold/Golos-Bold.ttf') format('truetype');
	font-weight: 700;
}

html {
	min-height: 100%;
	height: 100%;
}

body {
	font-weight: 400;
	background: $main-bg;
	font-size: 15px;
	line-height: 22px;
	min-width: 320px;
	height: 100%;
	margin: 0;
	width: 100%;
}

#root {
	display: flex;
	flex-direction: column;
}

* {
	font-family: Golos;
	outline: 0;
	-webkit-text-size-adjust: 100%;
}

::-ms-reveal {
	display: none;
}

.react-select__menu {
	margin: 0;
}

.no-scroll {
	overflow: hidden;
}

.no-scroll-action-btn {
	overflow: hidden;
}

section {
	position: relative;
	padding: 50px 0 0;
	&.actionBtnSection {
		padding-bottom: 50px;
	}
}

h3 {
	display: block;
	font-size: 15px;
	margin-block-start: 1em;
	margin-block-end: 1em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	font-weight: 600;
}

a {
	text-decoration: none;
	color: $dark-text;
	transition: all 0.2s linear;
}

input,
textarea {
	resize: none;
	border: 1px solid $light-text;
	border-radius: 2px;
	padding: 5px 10px;
	box-sizing: border-box;
	transition: 0.2s all;
	color: $dark-text;
	font-size: 15px;
	appearance: none;
	-webkit-appearance: none;
	outline: none;
}

input[type='number'] {
	appearance: none;
	-webkit-appearance: none;
	-moz-appearance: textfield !important;

	&::-webkit-outer-spin-button,
	&::-webkit-inner-spin-button {
		-webkit-appearance: none;
		-moz-appearance: none;
		appearance: none;
		display: none;
	}
}

.allPageLoader {
	width: 100%;
	height: 100vh;
	background: $main-bg;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10;
	padding: 0;
}

#main {
	padding: 0 0 40px 0;
}

.container {
	width: 100%;
	box-sizing: border-box;
	padding: 0 10px;
	margin: 0 auto;
}

.container_post {
	display: flex;
	flex-direction: column;
}

.user-search {
	&__block {
		position: absolute;
		border-radius: 2px;
		padding: 5px 10px;
		background: $white-bg;
		box-sizing: border-box;
		left: 0;
		top: 0;
		width: 100%;
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
		z-index: 1;
	}

	&__list {
		margin: 0;
		padding: 0;
	}

	&__item {
		list-style: none;
		color: $dark-text;
		transition: 0.2s all;
		cursor: pointer;
		display: flex;
		align-items: center;
		padding: 5px 0;

		> img,
		> svg {
			margin-right: 7px;
		}
	}

	&__no-results {
		font-size: 15px;
		color: $dark-text;
		padding: 5px 0;
	}
}

.cbx_body {
	display: flex;
	justify-content: flex-start;
	flex-direction: row;
	align-items: center;
	line-height: 15px;
	cursor: pointer;

	&.none {
		opacity: 0;
		visibility: hidden;
	}

	.cbx {
		display: block;
		width: 15px !important;
		height: 15px;
		padding: 0 !important;
		transition: 0.2s all;
		margin: 0 7px 0 0;
		box-sizing: border-box;
		border: 1px solid $light-text;
		border-radius: 2px;
		cursor: pointer;
		text-align: center;
		display: flex !important;
		justify-content: center;
		align-items: center;

		svg {
			display: block;
			fill: $white-bg;
			width: 10px;
			height: 10px;
			transition: 0.2s all;
			opacity: 0;
			margin: 0 0 0 1px;
		}

		&.active {
			border: 1px solid $red-text;
			background: $red-text;

			svg {
				opacity: 1;
			}
		}
	}
}

.label-with-hint {
	i {
		position: relative;
		font-style: normal;
		border-radius: 50%;
		border: 1px solid $dark-text;
		color: $dark-text;
		width: 15px;
		height: 15px;
		line-height: 11px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-left: 3px;
		text-align: center;

		&:before {
			position: absolute;
			content: '';
			border: 0;
			border-left: 10px solid transparent;
			border-right: 10px solid transparent;
			margin-left: -10px;
			left: 50%;
			border-bottom: 10px solid $white-bg;
			top: 100%;
			transition: 0.2s all;
			opacity: 0;
			visibility: hidden;
		}

		&:after {
			position: absolute;
			content: attr(data-text);
			left: 50%;
			transform: translateX(-50%);
			top: 165%;
			z-index: 5;
			width: 200px;
			padding: 10px;
			opacity: 0;
			visibility: hidden;
			transition: 0.2s all;
			border: 1px solid $light-text;
			color: $dark-text;
			font-size: 13px;
			line-height: 19px;
			-webkit-box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
			box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
			border-radius: 2px;
			background: $white-bg;
		}

		&:hover::before,
		&:hover::after {
			opacity: 1;
			visibility: visible;
		}
	}
}

.add-img {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-left: 15px;
	width: 30px;
	height: 30px;
	background: $red-bg;
	border-radius: 50%;
	transition: 0.2s all;
	cursor: pointer;
	color: $white-bg;

	svg {
		width: 14px;
		height: 14px;
		fill: $white-bg;
	}
}

.over-header-wrapper {
	margin-top: 0;
	position: relative;
}

.radius {
	border-radius: 2px;
}

.dark-text {
	color: $dark-text;
}

.light-text {
	color: $light-text;
}

.red-text {
	color: $red-text;
}

.grey-text {
	color: $grey-text;
}

.white-bg {
	background: $white-bg;
}

.grey-bg {
	background: $grey-bg;
}

.red-bg {
	background: $red-bg;
}

.dark-bg {
	background: $dark-text;
}

.red-hover {
	transition: all 0.2s linear;
}

.grey-hover {
	transition: all 0.2s linear;
}

.error-input,
.err-input {
	border-color: $red-bg !important;

	> div {
		border-color: $red-bg !important;
	}
}

.aside_right {
	margin: 20px 0;
}

.inline-link {
	font-weight: 400;
	color: $red-text;
	cursor: pointer;
	display: inline-flex;
	align-items: center;

	svg {
		width: 10px;
		height: 9px;
		transition: 0.2s all;
		fill: $red-bg;
		margin: 0 10px 0 0;
	}
}

.chars-count {
	display: flex;
	justify-content: flex-end;
	text-align: right;
	color: $grey-text;
	font-size: 11px;
	line-height: 16px;
}

.error-page {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;

	h3 {
		color: $dark-text;
		font-size: 25px;
		line-height: 34px;
		font-weight: 600;
		position: relative;
		text-align: center;
	}

	span {
		color: $dark-text;
		font-size: 17px;
		font-weight: 400;
		text-align: center;

		button {
			color: $red-text;
			padding: 0;
			cursor: pointer;
			border: 0;
			font-size: 17px;
			background: transparent;
			transition: 0.2s all;
		}
	}

	img {
		width: 100%;
		max-width: 860px;
		height: auto;
		margin: 100px 0 30px 0;
	}
}

.forbidden-section {
	height: calc(100vh - 80px);
	padding: 0;
}

.forbidden-page {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 38px 0 0;
	height: calc(100vh - 80px);

	h3 {
		color: $dark-text;
		font-size: 19px;
		line-height: 28px;
		font-weight: 600;
		position: relative;
		text-align: center;

		&:before {
			display: none;
		}
	}

	span {
		color: $dark-text;
		font-size: 15px;
		font-weight: 400;

		a {
			color: $red-text;
			transition: 0.2s all;
		}
	}

	svg {
		width: 100%;
		max-width: 200px;
		max-height: 90px;
	}
}

.group__owners {
	display: flex;
	align-items: flex-start;
	margin: 10px 0;

	ul {
		margin: 10px 0 0 0;
		padding: 0;

		li {
			list-style: none;
			display: flex;
			align-items: center;

			a {
				margin: 0 10px 0 0;
			}

			svg {
				width: 10px;
				height: 10px;
				fill: $dark-text;
				cursor: pointer;
				transition: 0.2s all;
			}
		}
	}

	> span {
		width: 30%;
		color: $dark-text;
	}

	> div {
		width: 70%;
	}

	a {
		color: $red-text;
	}
}

.live-search .search-result > div.nb-spinner {
	padding: 0;
}

.ReactCollapse--collapse {
	transition: height 800ms cubic-bezier(0.165, 0.84, 0.44, 1);
}

.section-wrapper {
	padding-bottom: 20px;
	@media (max-width: 991px) {
		padding-bottom: 50px;
	}
}

@media only screen and (min-width: 992px) {
	.forbidden-section {
		height: calc(100vh - 169px);
	}

	.forbidden-page {
		height: calc(100vh - 169px);
		padding: 0;

		svg {
			max-width: 860px;
			height: auto;
			max-height: 120px;
		}

		h3 {
			font-size: 25px;
			line-height: 34px;
		}

		span {
			font-size: 17px;
		}
	}

	.no-scroll-action-btn {
		overflow: initial;
	}

	.arrow-up {
		display: flex;
		height: 100%;
		padding-top: 110px;
		padding-left: 15px;

		svg {
			flex: 0 0 auto;
			width: 10px;
			height: 10px;
			transform: rotate(90deg);
			margin: 6px 10px 0 0;
			fill: $grey-hover;
			transition: 0.2s all;
		}

		span {
			font-size: 15px;
			font-weight: 500;
			color: $grey-hover;
			transition: 0.2s all;
		}

		&:hover {
			svg {
				fill: $dark-text;
			}

			span {
				color: $dark-text;
			}
		}
	}

	.container {
		max-width: 960px;
		width: calc(100% - 50px);
		padding: 0 25px;
	}

	.container_post {
		flex-direction: row;
	}

	.over-header-wrapper {
		margin-top: -37px;
	}

	section {
		padding: 119px 0 0;
	}

	.aside_right {
		margin: 0 0 0 25px;
	}

	.error-page {
		h3 {
			font-size: 31px;
			position: relative;

			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				width: 100%;
				max-width: 195px;
				height: 6px;
				background: $dark-text;
			}

			&::before {
				left: -210px;
				top: 14px;
			}

			&::after {
				right: -210px;
				top: 14px;
			}
		}

		span {
			font-size: 23px;
		}
	}

	.add-img {
		&:hover {
			background: $red-hover;
		}
	}

	.red-hover {
		&:hover {
			background: $red-hover;
		}
	}

	.grey-hover {
		&:hover {
			background: $grey-hover;
		}
	}

	.inline-link {
		&:hover svg {
			fill: $red-hover;
		}

		&:hover {
			color: $red-hover;
		}
	}

	.error-page {
		span {
			button {
				font-size: 23px;

				&:hover {
					color: $red-hover;
				}
			}
		}
	}

	.forbidden-page {
		span {
			a {
				&:hover {
					color: $red-hover;
				}
			}
		}
	}

	.group__owners {
		ul {
			li {
				svg {
					&:hover {
						fill: $red-hover;
					}
				}
			}
		}

		a {
			&:hover {
				color: $red-hover;
			}
		}
	}

	.fixed-sidebar {
		position: sticky;
		top: 119px;
		height: calc(100vh - 120px);
		margin-right: -14px;
		&__wrap {
			padding-right: 14px;
			padding-bottom: 20px;
		}
	}
	.custom-scrollbar {
		&__view {
			margin-right: -50px !important;
			margin-bottom: -50px !important;
			padding-right: 33px;
			padding-bottom: 33px;
		}
	}
}

@media (min-width: 1200px) {
	.container {
		max-width: 1256px;
	}
}

@keyframes loadingText {
	0% {
		background: #999;
		opacity: 0.1;
	}

	50% {
		background: #999;
		opacity: 0.2;
	}

	100% {
		background: #999;
		opacity: 0.1;
	}
}

.header-wrapper {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 4;
	box-shadow: 0px 7px 6px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	// background-image: url('/img/header-new-year-bg.jpg');
	// background-position: center;
	// background-size: auto 100%;
	// background-repeat: repeat-x;
	// .header {
	// 	padding: 10.5px 0;
	// }

	@media (min-width: 992px) {
		.header {
			position: relative;
			z-index: 3;
		}
		.navigation {
			position: relative;
			z-index: 2;
		}
	}
}
